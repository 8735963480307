<template>
  <div class="content">
    <vue-whatsapp-widget
      phoneNumber="+524421867547"
      companyName="tulum.party (Gaby)"
      textReply="Typically replies within 10-15 mins (9am - 10pm)"
      companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
    />
    <div
      class="row"
      :style="{
        background: ` ${this.primaryColor}`,
      }"
    >
      <div class="text-center col-lg-8 col-sm-12">
        <div style="margin-top: 50px">
          <div
            class="header text-center"
          >
            <br />
            <img
              v-lazy="logo + '?w=105&h=15&fit=clamp'"
              alt="app-logo"
              width="105px"
              height="15px"
              style="border-radius: 0; margin-top: 10px"
            />

            <p style="font-weight: lighter; font-size: 11px">
              DO NOT MISS A THING !
            </p>
            <br />


            <div class="card" style="margin: 10px;padding: 10px">

              <p
              >All Events ● Dinners w/ shows ● Daily Updates ● Clubs Activity ● Spontaneous events ●
                <router-link :to="{path: '/google-calendar-party'}">
                  Sync with any calendar app and/or Phone
                </router-link>
                ● Locations ● Start Times ● Event Details & Tickets ●
                <router-link :to="{path: '/google-calendar-party'}">Google Calendar
                  Version
                </router-link>
                Works
                Offline
              </p >
            </div>
          </div>
          <br />
          <div class="">


            <div
              class="col-12 text-center card"
              v-if="periodParties.length === 0"
            >
              <h4 class="badge-dark" style="margin-top: 90px">
                No event(s) found
              </h4>
              <small>Most events are published one or two weeks prior</small>
            </div>

            <div
              v-if="listView"
              :style="{
                background: `${secondaryColor}`,
              }"
              style="margin-top: 10px; padding: 3px; border: 2px solid #1d1d1d"
            >

              <div class="centered">
                <div class="row">

                  <div class="col-sm-5 offset-sm-1">
                    <small class="badge-dark"
                    >Show in results for: <strong> {{ periodLabel }}</strong>
                    </small>
                  </div>
                  <div class="col-sm-5 offset-sm-1">
                    <small class="badge-dark"
                    >{{ periodParties.length }} event(s) found</small
                    >
                  </div>
                </div>
                <br>
                <span @click="getPeriod('this_week', true)" class="btn-link"
                >This Week</span
                >|
                <span @click="getPeriod('next_week', true)" class="btn-link"
                >Next Week</span
                >|
                <span @click="getPeriod('this_month', true)" class="btn-link"
                >This Month</span
                >|
                <span @click="getPeriod('next_month', true)" class="btn-link" >Next Month</span >|
                <router-link :to="{path: '/google-calendar-party'}" target="_blank">
                  <span class="btn-link">All</span></router-link> | </div>

              <!--              <h2 class="text-center tulum-party-title-alt">{{periodLabel}}</h2>-->




              <div
                ref="calendar"
                class="col-md-10 offset-md-1 col-sm-10 offset-sm-1"
                :key="idx"
                style="margin-top: 10px"

                v-for="(event, idx) in periodParties"
              >
                <div v-if="periodPartiesDates[idx]">
                  <h3
                    class="text-left"
                    :style="`backgroundColor: ${secondaryColor}`"
                    style="
                      text-transform: uppercase;
                      font-weight: bold;
                      margin-bottom: 0;
                      padding: 30px;
                    "
                  >
                    {{
                      getDay(
                        event.metadata.date + 'T' + event.metadata.start_time
                      ) + " " +
                      getDay2(
                        event.metadata.date + 'T' + event.metadata.start_time
                      )
                    }}
                  </h3>

                </div>

                <!--                <MiniEventCard-->
                <!--                  :event="event"-->
                <!--                  withVenue="true"-->
                <!--                  :key="event.title"-->
                <!--                ></MiniEventCard>-->
                <div class="card text-left">
                  <router-link
                    :to="
                      event.metadata.magicEvent
                        ? { path: event.metadata.link + '?event='+event.slug}
                        : { name: 'Event', params: { slug: event.slug } }
                    "
                  >

                    <p class="animation-on-hover"> <span class="calendarPoint" ></span> <strong class="text-smart" style="padding: 10px">{{ event.metadata.start_time }}</strong>
                      {{ event.title.split("@")[0] }}</p>
                  </router-link>
                </div>
              </div>
            </div>



            <div
              class="lds-dual-ring"
              v-if="this.$store.state.status.loading"
              style="position: fixed; z-index: 9999"
            >
              LOADING
            </div>
          </div>

          <div class="row" style="margin-top: 40px">
                        <div
                          class="col-12 text-left card " style="background-color: black;padding: 20px">

                            <SmartMenu :links="links" :logo="this.logo" primary-color="black" top-section-name="PLAN AHEAD"
                                       cta-text=" 📲 DROP US A LINE">
                              <div class="row">
                                <div class="col-sm-12 text-center">
                                  <img v-lazy="djs+'?w=180&h=200&fit=clamp'" height="200px" width="180px" class="centered">
                                  <br>

                                  <p>Need some help planning or executing your plan, <b>drop us a line.</b> <br>Let's dive into the
                                    Tulum
                                    Party
                                    Elite Scene</p>
                                  <hr>
                                </div>
                              </div>
                            </SmartMenu>
                        </div>
          </div>
        </div>
      </div>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <HotThisWeek
          :primary-color="primaryColor"
          small="true"
          align="text-center"
        ></HotThisWeek>

        <SmartMenu
          :links="links"
          :logo="this.calImage"
          cta-text="RESERVE NOW"
          top-section-name="CALENDAR"
        >
          <router-link :to="{name: 'GoogleCalendar'}">
            <p>Complete Event Calendar, don't miss a thing ! </p>
            <br>
          </router-link>
        </SmartMenu>
      </aside>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import HotThisWeek from '@/pages/TulumDjSchedule/HotThisWeek';

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
  );

export default {
  name: 'PartyCalendar',
  data: () => {
    return {
      showPalms: false,
      primaryColor: '#003e3e',
      // secondaryColor: '#1f1f1d',
      // primaryColor: 'yellow',
      secondaryColor: '#007c7c',
      compact: false,
      showCalendarTab: false,
      showEventsTab: true,
      period: 'week',
      periodLabel: 'this_week',
      logo:
        'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      logito:
        'https://imgix.cosmicjs.com/8c49adc0-c592-11ee-8294-5bdacc8e0a52-astro-dj.jpg',
      djs:
        'https://imgix.cosmicjs.com/717f22a0-d081-11ee-9ce5-59949019255e-tulum-party-green.jpg',
      periodPartiesDates: [],
      blueEyeImage:
        'https://imgix.cosmicjs.com/91cad540-ac13-11ef-bee4-3bb1d3c55332-diver-rayado.jpg',
      listView: true,
      periodParties: [],
      mainImage:
        'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      calImage:
        'https://imgix.cosmicjs.com/00c12a20-7d7c-11ee-9baf-2960bf73c40e-image.png',
      gCalImg:
        'https://imgix.cosmicjs.com/cdf97730-803e-11ee-9ebe-eb22b0517bf0-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/717f22a0-d081-11ee-9ce5-59949019255e-tulum-party-green.jpg',
      birdsImg:
        'https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png',
    };
  },
  metaInfo() {
    return {
      title: `Tulum Parties, Events & Shows Calendar.`,
      link: [
        {
          rel: 'canonical',
          href: 'https://www.tulum.party/tulum-party-mexico-event-calendar',
        },
      ],
      meta: [
        {
          name: 'description',
          content: `Information, Location, Dates and Times for the Top Parties, Events, Shows & Dinners in Tulum & The Riviera Maya`,
        },
        {
          name: 'keywords',
          content: `Tulum Party Calendar. Upcoming Events @ Tulum Mexico, events, calendar, clubs, party agenda, dj agenda, dinner, shows`,
        },
        {
          property: 'og:title',
          content: `Tulum Parties, Events & Shows Calendar.`,
        },
        {
          property: 'og:url',
          href: 'https://www.tulum.party/tulum-party-mexico-event-calendar',
        },
        {
          property: 'og:description',
          content: `Information, Location, Dates and Times for the Top Parties, Events, Shows & Dinners in Tulum & The Riviera Maya`,
        },
        { property: 'og:type', content: 'website' },
      ],
    };
  },
  components: {
    HotThisWeek,
    SmartMenu,
    VueWhatsappWidget,
  },
  computed: {
    incomingParties: {
      get() {
        return this.$store.state.calendar;
      },
    },
    links: {
      get() {
        return this.$store.state.links || [];
      },
    },
    photos: {
      get() {
        return this.incomingParties.map((x) => x.metadata.main_image.imgix_url);
      },
    },
    photoCaptions: {
      get() {
        return this.incomingParties.map(
          (x) => x.title + ' - ' + x.metadata.date
        );
      },
    },
    thisMonth: {
      get() {
        return moment().format('MMMM');
      },
    },
    nextMonth: {
      get() {
        return moment().add('month', 1).format('MMMM');
      },
    },
  },
  mounted: function () {

    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    if (this.$route.query.compact) {
      this.compact = true;
    }
    this.getPeriod('this_week', true);
    this.$store.dispatch('getLinks', {
      slug: 'tulum-party-mexico-event-calendar',
    });
    this.$store.dispatch('getAllParties').then((_x) => {
      this.getPeriod(this.$route.query.period || 'this_week', true);
    });
    setTimeout(() => {
      this.showPalms = true;
    }, 3000);
  },
  methods: {
    goTo: function goTo(page) {
      this.$router.push({ path: page, replace: true });
    },
    onQuickReserveClick() {
      window.ga(
        'send',
        'event',
        'reservations',
        'PartySupport',
        'Calendar',
        20
      );
      this.$rollbar.info('PartySupport On Calendar');
    },
    getPeriod: function getPeriod(period, silent) {
      let label = '';
      if (period === 'today') {
        label = 'Today';
        this.getThisPeriod('day', label);
      }
      if (period === 'tomorrow') {
        label = 'Tomorrow';
        this.getNextPeriod('day', label);
      }
      if (period === 'this_week') {
        label = 'This Week';
        this.getThisPeriod('week', label);
      }
      if (period === 'next_week') {
        label = 'Next Week';
        this.getNextPeriod('week', label);
      }

      if (period === 'this_month') {
        label = this.thisMonth;
        this.getThisPeriod('month', label);
      }
      if (period === 'next_month') {
        label = this.nextMonth;
        this.getNextPeriod('month', label);
      }
      if (period === 'all') {
        label = 'All';
        this.$route.path
        // this.getThisPeriod('year', label);
      }
      if (!silent) {
        this.notifyChange('Showing events for ' + label);
        // this.$rollbar.info('Calendar: ' + label);
      }

    },

    getThisPeriod: function getThisPeriod(unitOfTime, label) {
      let parties = this.incomingParties;

      this.period = unitOfTime;
      this.periodLabel = label;
      this.periodParties = [...parties].filter((x) =>
        this.isIncomingParty(unitOfTime, x)
      );
      let _periodDates = {};
      this.periodPartiesDates = [];
      this.periodParties.forEach((y) => {
        if (_periodDates[y.metadata.date]) {
          this.periodPartiesDates.push(null);
        } else {
          _periodDates[y.metadata.date] = true;
          this.periodPartiesDates.push(y.metadata.date);
        }
      });
    },
    getNextPeriod: function getNextPeriod(unitOfTime, label) {
      let parties = this.incomingParties;
      this.period = unitOfTime;
      this.periodLabel = label;

        this.periodParties = [...parties].filter((x) =>
          this.isIncomingNext(unitOfTime, x)
        );
        let _periodDates = {};
        this.periodPartiesDates = [];
        this.periodParties.forEach((y) => {
          if (_periodDates[y.metadata.date]) {
            this.periodPartiesDates.push(null);
          } else {
            _periodDates[y.metadata.date] = true;
            this.periodPartiesDates.push(y.metadata.date);
          }
        });
      this.period = unitOfTime;
    },
    addHashToLocation(params, compact) {
      let path = this.$route.path + '?period=' + encodeURIComponent(params);
      if (compact) {
        path += '&compact=true';
      }
      history.pushState({}, null, path);
    },
    notifyChange: function notifyChange(change) {
      this.$notify({
        type: 'primary',
        title: 'Calendar',
        message: `${change}`,
        icon: 'tim-icons icon-bell-55',
      });
    },

    isIncomingParty: function isIncomingParty(unitOfTime, x) {
      return (
        moment()
          .startOf(unitOfTime)
          .isSame(x.metadata.date + 'T' + x.metadata.start_time, unitOfTime) &&
        (
          !x.metadata.magicEvent ||
          (x.metadata.magicEvent && x.metadata.show_in_calendar))
      );
    },

    isIncomingNext: function isIncomingNext(unitOfTime, x) {
      return (
        moment()
          .add(1, unitOfTime)
          .startOf(unitOfTime)
          .isSame(x.metadata.date + ' ' + x.metadata.start_time, unitOfTime) &&
        (
          !x.metadata.magicEvent ||
          (x.metadata.magicEvent && x.metadata.show_in_calendar))
      );
    },
    getDay: function getYear(date) {
      return moment(date, true).format('ddd');
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD');
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM');
    },
    googleCalendar() {
      window.ga('send', 'event', 'Event', 'Calendar', 'Google Calendar', 1);
      this.$rollbar.info(`Google Calendar Open`);
    },
  },
};
</script>
